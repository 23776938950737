import { useEffect, useState } from "react"
import { Space, Button, } from "antd"
import Map from "./components/Map/Map.js"
import SortList from "./components/sortList/index.js"
import { shipperInport, shipperOutport } from './components/sortListScrollBoard.js'

import ScrollBoard from "./components/ScrollBoard"
import TopModule from "./components/TopModule"
import RightModule from './components/LargeScreenModule/rightModule'
import LeftModule from './components/LargeScreenModule/leftModule'

import "./index.scss"
import { bigscreen, screenDataList } from "@/api"
import Quanp from '@/assets/quanp.svg'
import Shouqi from '@/assets/shouqi.svg'
export default function LargeScreen() {
  const [info, setinfo] = useState({});
  const [typeTabs, settypeTabs] = useState('my');
  const [currentSubsidiary, setcurrentSubsidiary] = useState({ value: "", label: "总公司" });
  const [showVedioInfo, setshowVedioInfo] = useState('');
  const [screenList, setScreenList] = useState({})
  const [isFullScreen, setIsFullScreen] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem("uid")) {
      window.location.href = "/login";
    }
    getbigscreen()
    screenData()
  }, [])
  const toggleFullScreen = () => {
    if (!isFullScreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) { // Firefox  
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera  
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) { // IE/Edge  
        document.documentElement.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox  
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera  
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge  
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  };
  const screenData = async () => {

    const { data } = await screenDataList()

    // screenList?.warehouseInventories?.[isIndex]?.productQuantities
    data?.warehouseInventories?.map((item, index) => {
      item.productQuantities.map((items, indexs) => {
        if (items) {
          items.name = items.variety;
          items.value = Number(items.quantity);
        }
        return items;
      })
      return item
    })
    setScreenList(data)
  }

  let rateZoom = window.screen.width / 1920;

  // if (currentEnvirement()=="PC"&&window.screen.width>1920) {
  //   rateZoom = window;
  // }
  const getbigscreen = async () => {
    try {
      const { data } = await bigscreen();

      setinfo(data)
    } catch (error) {
    }
    // debugger
  }
  const [sortListData, setsortListData] = useState([
    { name: 'Hinddasdn Zinc Ltd', num: 9572, unit: '' },
    { name: 'Ksdald Metals', num: 3589, unit: '' },
    { name: 'Glsdasde plc', num: 2393, unit: '' },
    { name: 'CsdafLCO', num: 1196, unit: '' },
    { name: 'Landin', num: 478, unit: '' },
  ])
  const sortListData2 = [
    { name: '**冶炼厂有限公司', num: 113300, unit: '吨' },
    { name: '**金属冶炼厂有限公司', num: 15520, unit: '吨' },
    { name: '**纯铁有限公司', num: 1231, unit: '吨' },
    { name: '**钢汇金属材料有限公司', num: 5520, unit: '吨' },
    { name: '**有色金属有限责任公司', num: 1231, unit: '吨' },
  ];
  const [sortListData1, setsortListData2] = useState(sortListData2.map(m => ({ ...m, unit: "元" })));
  return (
    <div className="demo_largescreen_container">
      <div className="demo_echarts_header">
        {/* <div style={{color:'#fff'}}>{typeTabs}</div> */}
        <TopModule settypeTabs={settypeTabs} screenList={screenList} setcurrentSubsidiary={setcurrentSubsidiary} />
      </div>
      <div className={typeTabs != "ck" ? "map_container" : "map_container map_container_storage"}>
        <Map rateZoom={rateZoom} typeTabs={typeTabs} currentSubsidiary={currentSubsidiary} showVedioInfo={showVedioInfo} />
        {typeTabs != 'ck_force-to-my' && <Space size={[18, 10]} className="demo_bottom_list">
          {screenList?.importerRankings?.length > 0 && <ScrollBoard title="发货人进口排名(车)" configData={screenList?.importerRankings} currentSubsidiary={currentSubsidiary} />}
          {screenList?.exporterRankings?.length > 0 && <ScrollBoard title="发货人出口排名(车)" configData={screenList?.exporterRankings} currentSubsidiary={currentSubsidiary} />}
        </Space>}
      </div>
      <div className={typeTabs != "ck_force-to-my" ? 'tj_pictureLayerLeft' : "tj_pictureLayerLeft tj_pictureLayerLeft_storage"}>
        <LeftModule info={info} typeTabs={typeTabs} ket={typeTabs} currentSubsidiary={currentSubsidiary} setshowVedioInfo={setshowVedioInfo} screenList={screenList} />
      </div>

      <div className={typeTabs != "ck_force-to-my" ? 'tj_pictureLayerRight' : "tj_pictureLayerRight tj_pictureLayerRight_storage"}>
        {<RightModule isFullScreen={isFullScreen ? Shouqi : Quanp} info={info} rateZoom={rateZoom} currentSubsidiary={currentSubsidiary} typeTabs={typeTabs} setshowVedioInfo={setshowVedioInfo} screenList={screenList} />}
      </div>
      <div
      className="full_screen_btn"
      >
        <img onClick={toggleFullScreen} 
        src={isFullScreen ? Shouqi : Quanp} 
         />
      </div>
    </div>
  )
}